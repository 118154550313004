import ChevronRight from '../../../assets/images/chevron-right.inline.svg';
import { Link } from '../link';

export type OverviewListItemType = {
  slug?: string;
  metadataTags?: {
    description?: string | undefined;
  };
  title?: string;
};

export const OverviewListItem: React.FC<OverviewListItemType> = ({
  slug = '',
  metadataTags = {
    description: '',
  },
  title = '',
}) => (
  <div className="flex flex-col justify-between items-start p-6 h-full bg-grey-20 dark:bg-white rounded-lg border border-grey-300 dark:bg-opacity-[0.1]">
    <div className="flex-col justify-between">
      <h6 className="mb-4 text-blue-900 dark:text-grey-20">{title}</h6>
      <p className="mb-5 text-xxs leading-relaxed text-light-black dark:text-light-grey line-clamp-2 md:line-clamp-4">
        {metadataTags.description}
      </p>
    </div>
    <div className="inline-flex justify-center items-center ">
      <Link
        aria-label="Click to navigate to"
        className="inline-flex items-baseline text-xxs text-external-link dark:text-dark-card-link hover:underline focus:underline"
        href={slug}
        isOpenedInNewTab={false}
      >
        Start
        <ChevronRight className="ml-1.5" height={8} />
      </Link>
    </div>
  </div>
);
