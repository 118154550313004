import ChevronRight from 'assets/images/chevron-right.inline.svg';
import { Link } from 'components/common/link';

type CardItemType = {
  title: string;
  content: string;
  callToActionLink: string;
  callToActionLabel: string;
};

export type TripleCardSectionType = {
  heading: string;
  cardItems: CardItemType[];
};

export const TripleCardSection: React.FC<TripleCardSectionType> = ({
  heading,
  cardItems,
}) => (
  <div className="container px-5 mx-auto">
    <section className="text-blue-900 dark:text-white section">
      <h2 className="mb-11 text-3xl font-bold leading-extra-tight text-center">
        {heading}
      </h2>
      <ul className="grid grid-cols-1 gap-8 lg:grid-cols-3">
        {cardItems.map(
          (
            {
              title,
              content,
              callToActionLink,
              callToActionLabel,
            }: CardItemType,
            index,
          ) => (
            <li key={`${title}-${index}`}>
              <article className="h-full bg-white dark:bg-white/10 rounded-2xl shadow-card">
                <div className="flex flex-col justify-between p-8 h-full">
                  <div className="line-clamp-6">
                    <h3 className="mb-5 text-[22px] leading-normal break-all line-clamp-1 lg:text-[18px] xl:text-[22px]">
                      {title}
                    </h3>
                    {content}
                  </div>

                  {callToActionLink && callToActionLabel && (
                    <Link
                      className="flex items-center mt-8 text-lg text-blue-700 dark:text-dark-card-link hover:underline focus:underline lg:text-base xl:text-lg"
                      href={callToActionLink}
                    >
                      {callToActionLabel}
                      <ChevronRight className="mt-0.5 ml-3" height={11} />
                    </Link>
                  )}
                </div>
              </article>
            </li>
          ),
        )}
      </ul>
    </section>
  </div>
);
