import CheckIcon from 'assets/images/check.svg';

type ListItemType = {
  text: string;
};

export type FeatureListType = {
  heading: string;
  listItems: ListItemType[];
};

export const FeatureList: React.FC<FeatureListType> = ({
  heading,
  listItems,
}) => (
  <section className="pt-base pb-l feature-list">
    <em className="block text-lg not-italic leading-extra-snug text-left text-blue-900 dark:text-grey-20 feature-list-header">
      {heading}
    </em>
    {/* this is not a ul/li because .cms-content styles will override all tailwind classes */}
    <div>
      {listItems.map(({ text }, index) => (
        <div className="flex items-start mb-3" key={index}>
          <div className="flex shrink-0 justify-center items-center mr-base w-6 h-6 bg-green-400 rounded-full">
            <CheckIcon />
          </div>

          <span className="min-w-0 text-base leading-normal text-grey-700 dark:text-grey-20 break-words">
            {text}
          </span>
        </div>
      ))}
    </div>
  </section>
);
