import classNames from 'classnames';

export const SectionSeparator: React.FC<{
  className?: string;
}> = ({ className }) => (
  <hr
    className={classNames(
      className,
      'border-grey-200 dark:border-grey-700 my-l',
    )}
  />
);
