import SyntaxHighlighter, {
  SyntaxHighlighterProps,
} from 'react-syntax-highlighter';
import { agate } from 'react-syntax-highlighter/dist/cjs/styles/hljs';

import { CopyClipboard } from '../copy-clipboard/copy-clipboard';

const UITheme = agate;

type CodeSnippetType = {
  code: string;
  language?: SyntaxHighlighterProps['language'];
  style?: typeof UITheme;
  inline?: boolean;
  className?: string;
};

export const CodeLabel: React.FC<{ text: string }> = ({ text }) => {
  return (
    <div className="absolute top-base left-base text-sm">
      <span className="text-white text-opacity-75">
        {/* cpp is displayed as 'C++' in the datocms editor, so mirroring that here */}
        {text === 'cpp' ? 'C++' : text}
      </span>
    </div>
  );
};

export const CodeSnippet: React.FC<CodeSnippetType> = ({
  code,
  language,
  style = UITheme,
  inline,
  className,
  ...rest
}) => {
  const codeWithoutWhitespace = code.replace(/\s/g, '');
  if (codeWithoutWhitespace === '') return null;

  return !inline && language ? (
    <div className="relative code-snippet">
      <CodeLabel text={language} />
      <SyntaxHighlighter
        language={language}
        showLineNumbers
        style={style}
        {...rest}
      >
        {code}
      </SyntaxHighlighter>
      <CopyClipboard className="absolute top-base right-m" copyText={code} />
    </div>
  ) : (
    <span className="inline-code">
      <code className={className} {...rest}>
        {code}
      </code>
    </span>
  );
};
