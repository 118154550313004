import classNames from 'classnames';
import { memo, useEffect, useState } from 'react';

const DISMISS_TIMEOUT = 1500;

type CopyClipboardButtonType = {
  copyText: string;
  className?: string;
};

const CopyClipboardButton: React.FC<CopyClipboardButtonType> = ({
  copyText,
  className,
}) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isCopied) {
      timer = setTimeout(() => {
        setIsCopied(false);
      }, DISMISS_TIMEOUT);
    }

    return () => clearTimeout(timer as NodeJS.Timeout);
  }, [isCopied]);

  async function copyTextToClipboard(text: string) {
    const writeText = await navigator.clipboard.writeText(text);

    return writeText;
  }

  const handleCopy = () => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <button
      className={classNames(
        'text-sm leading-loose bg-transparent text-blue-400 font-code',
        className,
      )}
      onClick={handleCopy}
    >
      {isCopied ? 'copied!' : 'copy'}
    </button>
  );
};

export const CopyClipboard = memo(CopyClipboardButton);
