import {
  isBlockquote,
  isCode,
  isHeading,
  isLink,
  isParagraph,
} from 'datocms-structured-text-utils';
import { renderRule, StructuredText } from 'react-datocms';

import { getVersionedLink } from 'components/common/version-select/versionUtils';
import { SuiteVersionRecord } from 'types';
import { ComponentPageOptions } from 'types/page-types';

import { CodeSnippet } from '../../common/code-snippet';
import { Heading } from '../heading';
import { getComponentByKey } from '../utils/modular-content';

// TODO: add proper typings
export const StructuredTextContent: React.FC<{
  structuredText: any;
  suiteVersion?: SuiteVersionRecord;
}> &
  ComponentPageOptions = ({
  structuredText,
  suiteVersion,
  ...options
}): JSX.Element => {
  return (
    <div className="structured-text cms-content">
      <StructuredText
        customRules={[
          renderRule(isHeading, ({ node, children, key }) => (
            <Heading {...{ ...node, children }} key={key} />
          )),
          renderRule(
            isParagraph,
            ({ adapter: { renderNode }, children, key, ancestors }) => {
              const isBlockquoteAncestor =
                ancestors.find((a) => a.type === 'blockquote') !== undefined;
              const sanitizedChildren = children
                ?.map((child, idx) =>
                  typeof child !== 'string' && child?.type === 'code' ? (
                    <CodeSnippet
                      code={child?.props.children[0]}
                      inline
                      key={`${key}-c-${idx}`}
                    />
                  ) : (
                    child
                  ),
                )
                .filter(
                  (child) =>
                    // don't include ancestors of blockquotes that are empty
                    !isBlockquoteAncestor ||
                    !(typeof child === 'string' && child.trim().length === 0),
                );

              if (!sanitizedChildren || sanitizedChildren.length === 0)
                return null;

              return renderNode('p', { key }, sanitizedChildren);
            },
          ),
          renderRule(isLink, ({ node, children, key }) => (
            <a
              {...{ ...node }}
              href={getVersionedLink(node.url, suiteVersion)}
              key={key}
            >
              {children}
            </a>
          )),
          renderRule(
            isBlockquote,
            ({ adapter: { renderNode }, key, node, children }) => {
              const childrenWithAttribution = node.attribution
                ? [
                    ...(children || []),
                    renderNode(`footer`, { key: 'footer' }, node.attribution),
                  ]
                : children;

              // filter out blockquotes with no children
              if (
                !childrenWithAttribution ||
                childrenWithAttribution?.length === 0
              )
                return null;

              return renderNode('blockquote', { key }, childrenWithAttribution);
            },
          ),
          renderRule(isCode, ({ node, key }) => {
            return (
              <CodeSnippet
                code={node.code}
                key={key}
                language={node.language}
              />
            );
          }),
        ]}
        // @TODO typing once more modules available from CMS
        data={structuredText}
        // @TODO typing once more modules available from CMS
        renderBlock={({ record }: any): any => {
          return getComponentByKey({ ...record, suiteVersion, ...options });
        }}
      />
    </div>
  );
};

StructuredTextContent.pageOptions = [
  'allPagesOverview',
  'allUploads',
  'suiteVersion',
];
