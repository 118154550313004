import classNames from 'classnames';
import { FC } from 'react';

import { Button } from '../../common/button';

export type SectionContentType = {
  heading: string;
  content: string;
  callToActionLink: string;
  callToActionLabel: string;
  className: string;
};

export const SectionContent: FC<SectionContentType> = ({
  heading,
  content,
  callToActionLink,
  callToActionLabel,
  className,
}) => (
  <div className={classNames(className, 'flex flex-col justify-center')}>
    <div>
      <h2 className="mb-4 font-normal leading-tight break-words h3">
        {heading}
      </h2>

      <p className="mb-8 max-w-md text-base font-normal leading-normal">
        {content}
      </p>

      {callToActionLink && callToActionLabel && (
        <div className="mt-auto mb-base">
          <Button href={callToActionLink} isLink>
            {callToActionLabel}
          </Button>
        </div>
      )}
    </div>
  </div>
);
