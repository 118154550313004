import { FC } from 'react';

import { Image, ImageType } from 'components/common/image';

export type SectionImageType = {
  image: ImageType;
  className: string;
};

export const SectionImage: FC<SectionImageType> = ({ image, className }) => {
  return (
    <div className={className}>
      <Image
        className="object-cover object-center w-full"
        image={image}
        layout="responsive"
      />
    </div>
  );
};
