import classNames from 'classnames';
import React, { FC, MouseEvent } from 'react';

export type ChipType = {
  text: string;
  selected?: boolean;
  onClick: (e: MouseEvent) => void;
};

export const Chip: FC<ChipType> = ({ text, selected = false, onClick }) => {
  const containerStyles = [
    'flex',
    'py-1',
    'px-4',
    'rounded-full',
    'transition',
    'duration-300',
    'ease',
    'cursor-pointer',
    'align-center',
  ];
  const textStyles = ['text-xxs', 'tracking-tight', 'leading-normal'];

  return (
    <button
      aria-controls={`${text}-tab`}
      aria-selected={selected}
      className={classNames(
        containerStyles,
        selected
          ? 'bg-green-900 dark:bg-grey-100'
          : 'bg-grey-100 dark:bg-green-900',
      )}
      id={`filter-${text}`}
      onClick={onClick}
      role="tab"
      tabIndex={0}
    >
      <span
        className={classNames(
          textStyles,
          selected
            ? 'text-green-400 dark:text-blue-900'
            : 'text-blue-900 dark:text-green-400',
        )}
      >
        {text}
      </span>
    </button>
  );
};
