import classNames from 'classnames';
import NextImage from 'next/image';
import { useState } from 'react';

import { Link } from 'components/common/link';

export type ImageType = {
  url: string;
  alt: string;
  width: number;
  height: number;
};

type ImagePropTypes = {
  image: ImageType;
  layout?: 'fill' | 'fixed' | 'intrinsic' | 'responsive' | undefined;
  className?: string;
  placeholder?: boolean;
  allowClickToEnlarge?: boolean;
  priority?: boolean;
};

export const Image: React.FC<ImagePropTypes> = (props) => {
  const {
    image,
    allowClickToEnlarge,
    layout,
    className,
    placeholder = true,
    ...rest
  } = props;
  const [loaded, setLoaded] = useState(false);

  const ImageWithPlaceholder = () => (
    <>
      {placeholder && (
        <div
          className={classNames(
            'absolute inset-0 w-full h-full bg-grey-40 dark:bg-opacity-30 rounded-sm',
            {
              'animate-pulse': !loaded,
              'animate-out opacity-0': loaded,
            },
          )}
        ></div>
      )}
      <div
        className={classNames({
          'opacity-0': !loaded,
          'animate-load': loaded,
        })}
      >
        <NextImage
          alt={image.alt}
          className={classNames('h-full', className)}
          height={image.height}
          layout={layout}
          // to test placeholder / loading:
          // onLoadingComplete={() => setTimeout(() => setLoaded(true), 3000)}
          onLoadingComplete={() => setLoaded(true)}
          src={image.url}
          width={image.width}
          {...rest}
        />
      </div>
    </>
  );

  if (allowClickToEnlarge) {
    return (
      <div className="overflow-hidden relative w-full h-full">
        <Link
          attrs={{
            title: 'Click to enlarge image. ',
          }}
          href={image.url}
          isOpenedInNewTab
        >
          <ImageWithPlaceholder />
        </Link>
      </div>
    );
  }

  return (
    <div className="overflow-hidden relative w-full h-full">
      <ImageWithPlaceholder />
    </div>
  );
};
