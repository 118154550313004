import { ComponentPageOptions, SuiteVersionRecord } from 'types';

import { Button } from '../button';
import { getVersionedLink } from '../version-select/versionUtils';

type ButtonType = {
  id: string;
  label: string;
  link: string;
  buttonType: 'primary' | 'secondary';
};

export type ButtonBarType = {
  buttonBar: ButtonType[];
  suiteVersion?: SuiteVersionRecord;
};

export const ButtonBar: React.FC<ButtonBarType> & ComponentPageOptions = ({
  buttonBar,
  suiteVersion,
}) => {
  // ensure we have 1 primary and 1 secondary button
  // if none are primary, choose the first 1
  const primaryIdx = buttonBar.findIndex((b) => b.buttonType === 'primary');
  const [primary, secondary] =
    primaryIdx <= 0 ? buttonBar : buttonBar.reverse();

  return (
    <div className="flex flex-col items-center sm:flex-row">
      <Button
        className="mb-base w-full sm:mr-l sm:w-auto"
        href={getVersionedLink(primary.link, suiteVersion)}
        isLink
        key={`primary-${primary.id}`}
        role="link"
        type="button"
        variant="primary"
      >
        {primary.label}
      </Button>
      <Button
        className="mb-base w-full sm:w-auto"
        href={getVersionedLink(secondary.link, suiteVersion)}
        isLink
        key={`secondary-${primary.id}`}
        role="link"
        type="button"
        variant="secondary"
      >
        {secondary.label}
      </Button>
    </div>
  );
};

ButtonBar.pageOptions = ['suiteVersion'];
