import classNames from 'classnames';
import { FC, ReactNode } from 'react';

type SectionColumnType = {
  orderLast?: boolean;
  children: ReactNode;
};

export const SectionColumn: FC<SectionColumnType> = ({
  orderLast,
  children,
}) => {
  return (
    <div
      className={classNames(
        `grid grid-cols-6 col-span-full md:col-span-6 gap-m`,
        { 'order-last md:order-none': orderLast },
      )}
    >
      {children}
    </div>
  );
};
