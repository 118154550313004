import classNames from 'classnames';

import DownloadIcon from '../../../assets/images/download-icon.svg';

import { DownloadLink } from './download-link';

export type DownloadButtonType = {
  title?: string;
  className?: string;
  url: string;
};

const BASE_CLASSES = [
  'text',
  'text-grey-800',
  'dark:text-blue-900',
  'bg-grey-20',
  'hover:bg-grey-40',
  'dark:bg-grey-40',
  'dark:hover:bg-grey-100',
  'rounded-full',
  'border',
  'border-grey-200',
  'border-opacity-100',
  'inline-flex',
  'items-center',
  'justify-center',
  'outline-none',
  'px-base',
  'py-[0.625rem]',
  'cursor-pointer',
  'tracking-normal',
  'transition-colors',
  'duration-300',
];

export const DownloadButton: React.FC<DownloadButtonType> = ({
  className,
  title,
  url,
}) => {
  const buttonClassName = classNames(className, BASE_CLASSES);

  return (
    <div className="my-s">
      <DownloadLink className={buttonClassName} title={title} url={url}>
        <span className="inline-flex items-center mt-[-1px]">
          <DownloadIcon className="mr-4" height={15} width={15} />
          {title || 'Download API content'}
        </span>
      </DownloadLink>
    </div>
  );
};
