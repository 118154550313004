import { ReactNode } from 'react';

import { useDownloadFile } from '../../../lib/customHooks';
import { getFileName } from '../../../lib/utils';

export type DownloadLinkType = {
  title?: string;
  className?: string;
  url: string;
  children: ReactNode;
};

export const DownloadLink: React.FC<DownloadLinkType> = ({
  title,
  url,
  className,
  children,
}) => {
  const { handleFileDownload, fileUrl, ref } = useDownloadFile(url);
  const fileName = getFileName(url);

  const label = title || 'Download API content';

  return (
    <>
      <a
        aria-hidden
        className="hidden"
        download={fileName}
        href={fileUrl}
        ref={ref}
      >
        {`Download ${fileName}`}
      </a>
      <button
        aria-label={label}
        className={className}
        onClick={handleFileDownload}
        type="button"
      >
        {children}
      </button>
    </>
  );
};
