import { MarkdownBody } from 'components/cms/markdown-body';
import { StructuredTextContent } from 'components/cms/structured-text-content';
import { Table } from 'components/cms/table';
import { Admonition } from 'components/common/admonition';
import { ButtonBar } from 'components/common/button-bar';
import { DownloadButton } from 'components/common/download-button';
import { FeatureList } from 'components/common/feature-list';
import { Image } from 'components/common/image';
import { OverviewList } from 'components/common/overview-list';
import { DoubleCardSection } from 'components/modules/double-card-section';
import { DownloadModule } from 'components/modules/download-module';
import { HeroSection } from 'components/modules/hero-section';
import { ProductTileSection } from 'components/modules/product-tile-section';
import { TextImageSection } from 'components/modules/text-image-section';
import { TripleCardSection } from 'components/modules/triple-card-section';
import { ContentComponentProps, PageOption, PageOptions } from 'types';
import {
  GqlContentPageModelContentField,
  GqlHomePageModelContentField,
  Maybe,
} from 'types/dato-types';

// @TODO typing once more modules available from CMS
const contentTypes: { [key: string]: any } = {
  structured_text: StructuredTextContent,
  markdown: MarkdownBody,
  admonition: Admonition,
  download_button: DownloadButton,
  download_module: DownloadModule,
  feature_list: FeatureList,
  overview_list: OverviewList,
  double_card_section: DoubleCardSection,
  triple_card_section: TripleCardSection,
  table: Table,
  text_image_section: TextImageSection,
  hero: HeroSection,
  product_tile_section: ProductTileSection,
  button_bar: ButtonBar,
  image: Image,
};

export const getComponentByKey = ({
  id,
  _modelApiKey: apiKey,
  ...rest
}: ContentComponentProps): JSX.Element => {
  const Component = contentTypes[apiKey];

  // exclude pageOptions from options if they are not required
  // on the component
  const { allPagesOverview, allUploads, suiteVersion, ...options } = rest;
  const allOptions = { allPagesOverview, allUploads, suiteVersion };

  const pageOptions: PageOptions = Component.pageOptions?.reduce(
    (opt: PageOptions, prop: PageOption) => ({
      ...opt,
      [prop]: allOptions[prop],
    }),
    {},
  );

  return (
    <div id={id} key={id} suppressHydrationWarning={true}>
      {process.browser && Component && (
        <Component key={id} {...options} {...pageOptions} />
      )}
    </div>
  );
};

export const renderModularContent = (
  content: Maybe<
    Array<GqlContentPageModelContentField | GqlHomePageModelContentField>
  >,
  options?: PageOptions,
): Array<JSX.Element> | null => {
  if (!content) return null;

  // @TODO typing once more modules available from CMS
  return content.map((contentModule): JSX.Element => {
    const module = getComponentByKey({ ...contentModule, ...options });
    // return empty fragment instead of null due to map return original type of array
    if (!module) return <></>;

    return module;
  });
};
