import { FC } from 'react';

import { SectionColumn } from './section-column';
import { SectionContent } from './section-content';
import { SectionImage } from './section-image';

type ImageType = {
  url: string;
  alt: string;
  width: number;
  height: number;
};

export type TextImageSectionType = {
  heading: string;
  content: string;
  callToActionLink: string;
  callToActionLabel: string;
  imageToRight: boolean;
  image: ImageType;
};

const ImageToRightTemplate: FC<TextImageSectionType> = (props) => {
  return (
    <>
      <SectionColumn>
        <SectionContent
          className="col-span-full md:col-span-4 md:col-start-2"
          {...props}
        />
      </SectionColumn>
      <SectionColumn>
        <SectionImage
          {...props}
          className="relative col-span-full md:col-span-5"
        />
      </SectionColumn>
    </>
  );
};

const ImageToLeftTemplate: FC<TextImageSectionType> = (props) => {
  return (
    <>
      <SectionColumn orderLast>
        <SectionImage
          {...props}
          className="relative col-span-full md:col-span-5 md:col-start-2"
        />
      </SectionColumn>
      <SectionColumn>
        <SectionContent
          className="col-span-full md:col-span-4 md:col-start-2"
          {...props}
        />
      </SectionColumn>
    </>
  );
};

export const TextImageSection: FC<TextImageSectionType> = (props) => {
  return (
    <div className="container px-5 mx-auto">
      <section className="text-blue-900 dark:text-white section">
        <div className="px-4 mx-auto max-w-screen-2xl md:px-8">
          <div className="grid grid-cols-12 gap-m">
            {props.imageToRight ? (
              <ImageToRightTemplate {...props} />
            ) : (
              <ImageToLeftTemplate {...props} />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};
