import ReactMarkdown from 'react-markdown';

import { getVersionedLink } from 'components/common/version-select/versionUtils';
import { ComponentPageOptions } from 'types';
import { SuiteVersionRecord } from 'types/versions-types';

import { CodeSnippet } from '../../common/code-snippet';
import { Heading } from '../heading';

export const MarkdownBody: React.FC<{
  markdown?: string;
  suiteVersion?: SuiteVersionRecord;
}> &
  ComponentPageOptions = ({ markdown, suiteVersion }) => {
  if (!markdown) return null;

  return (
    <div className="markdown-body cms-content">
      <ReactMarkdown
        components={{
          h1: (node) => <Heading {...{ ...node, key: null }} level={2} />, // h1 is rendered elsewhere to ensure only one h1 per page
          h2: (node) => <Heading {...{ ...node, key: null }} />,
          h3: (node) => <Heading {...{ ...node, key: null }} />,
          h4: (node) => <Heading {...{ ...node, key: null }} />,
          h5: (node) => <Heading {...{ ...node, key: null }} />,
          a: ({ href, children, ...props }) => (
            <a href={getVersionedLink(href || '', suiteVersion)} {...props}>
              {children}
            </a>
          ),
          // do not remove 'node' property to prevent it being passed as props
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          code: ({ node, inline = false, className, children, ...props }) => {
            const isLanguageMatch = /language-(\w+)/.exec(className || '');
            const language = isLanguageMatch
              ? isLanguageMatch[1]
              : 'javascript';

            return (
              <CodeSnippet
                code={String(children).replace(/\n$/, '')}
                inline={inline}
                language={language}
                {...props}
              />
            );
          },
        }}
        skipHtml
      >
        {markdown}
      </ReactMarkdown>
    </div>
  );
};

MarkdownBody.pageOptions = ['suiteVersion'];
