import ChevronRight from 'assets/images/chevron-right.inline.svg';
import { Image, ImageType } from 'components/common/image';
import { Link } from 'components/common/link';

type CardItem = {
  title: string;
  content: string;
  callToActionLink: string;
  callToActionLabel: string;
  image: ImageType;
};

export type DoubleCardSectionType = {
  cardItems: CardItem[];
};

export const DoubleCardSection: React.FC<DoubleCardSectionType> = ({
  cardItems,
}) => (
  <div className="container px-5 mx-auto lg:px-24">
    <section className="section">
      <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-0 lg:my-10 lg:-mx-12 lg:divide-x lg:divide-grey-200 lg:dark:divide-grey-700">
        {cardItems.map(
          (
            {
              title,
              content,
              callToActionLink,
              callToActionLabel,
              image,
            }: CardItem,
            index,
          ) => (
            <article key={`${title}-${index}`}>
              <div className="text-blue-900 dark:text-white bg-white rounded-3xl shadow-card lg:-my-10 lg:mx-9 dark:bg-opacity-10">
                <div className="overflow-hidden relative w-full rounded-t-3xl m-h-40">
                  <Image
                    className="object-cover absolute inset-0 w-full h-full"
                    image={image}
                    layout="responsive"
                  />
                </div>
                <div className="flex flex-col justify-between p-8 h-72">
                  <div className="text-md line-clamp-4">
                    <h3 className="mb-5 text-2xl font-semibold leading-tight">
                      {title}
                    </h3>
                    {content}
                  </div>

                  {callToActionLink && callToActionLabel && (
                    <Link
                      className="flex items-center mt-8 text-lg text-blue-700 dark:text-dark-card-link hover:underline focus:underline"
                      href={callToActionLink}
                    >
                      {callToActionLabel}
                      <ChevronRight className="mt-0.5 ml-3" height={11} />
                    </Link>
                  )}
                </div>
              </div>
            </article>
          ),
        )}
      </div>
    </section>
  </div>
);
