import classNames from 'classnames';

import BellIcon from 'assets/images/bell-icon.svg';
import InfoCircleIcon from 'assets/images/info-circle-icon.svg';
import WarningCircleIcon from 'assets/images/warning-cirle-icon.svg';

export const INFO = 'Info';
export const CAUTION = 'Caution';
export const DANGER = 'Danger';

const INFO_ICON = 'info-circle';
const CAUTION_ICON = 'warning-circle';
const DANGER_ICON = 'bell';

export type AdmonitionType = {
  admonitionType: typeof INFO | typeof CAUTION | typeof DANGER;
  heading?: string;
  text: string;
  className?: string;
};

export type IconType =
  | typeof INFO_ICON
  | typeof CAUTION_ICON
  | typeof DANGER_ICON;

type IconProps = {
  icon: IconType;
  height?: number;
  width?: number;
};

export const Icon = ({ icon, height, width }: IconProps) => {
  switch (icon) {
    case INFO_ICON:
      return (
        <InfoCircleIcon
          className={'text-green-600'}
          height={height}
          width={width}
        />
      );
    case CAUTION_ICON:
      return (
        <WarningCircleIcon
          className={'text-orange'}
          height={height}
          width={width}
        />
      );
    case DANGER_ICON:
      return <BellIcon className={'text-red'} height={height} width={width} />;
    default:
      return <></>;
  }
};

const BASE_CONTAINER_CLASSES = [
  'bg-opacity-15',
  'border-l-5',
  'break-words',
  'leading-normal',
  'my-base',
  'py-s',
  'pl-m',
  'pr-l',
  'rounded',
  'text-base',
  'text-grey-700',
  'tracking-extra-tight',
  'dark:text-grey-20',
];

export const Admonition: React.FC<AdmonitionType> = ({
  admonitionType,
  heading,
  text,
  className,
}) => {
  const typeMap = {
    [INFO]: {
      base: 'bg-green-400 border-green-400',
      icon: INFO_ICON,
      iconStyle: 'text-green-600',
    },
    [CAUTION]: {
      base: 'bg-gold-400 border-gold-400',
      icon: CAUTION_ICON,
    },
    [DANGER]: {
      base: 'bg-salmon-500 border-red',
      icon: DANGER_ICON,
    },
  };

  return (
    <div
      className={classNames(
        'admonition',
        BASE_CONTAINER_CLASSES,
        typeMap[admonitionType].base,
        className,
      )}
    >
      {heading && (
        <h5
          className={
            'block mb-xs text-base font-bold break-words admonition-header'
          }
        >
          {heading}
        </h5>
      )}
      <div className="flex items-center">
        <div className="self-start mr-s">
          <Icon
            height={25}
            icon={typeMap[admonitionType].icon as IconType}
            width={25}
          />
        </div>
        <p>{text}</p>
      </div>
    </div>
  );
};
