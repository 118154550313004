import classNames from 'classnames';
import React, { FC, RefObject, useEffect, useRef, useState } from 'react';

import {
  AllPagesOverviewType,
  ComponentPageOptions,
  OverviewSuite,
} from 'types';

import { Button } from '../button';
import { Chip } from '../chip';

import { OverviewListItem } from './overview-list-item';

export const MEDIUM_VIEW_WIDTH = 768;
export const LARGE_VIEW_WIDTH = 1280;
export const MAX_NUM_TO_SHOW = 18;

export type OverviewListType = {
  title: string;
  listItems: OverviewSuite[];
  allPagesOverview?: AllPagesOverviewType;
};

export const OverviewList: FC<OverviewListType> & ComponentPageOptions = (
  props,
) => {
  const { title, listItems, allPagesOverview } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [filterIndex, setFilterIndex] = useState(0);
  const INIT_NUM_TO_SHOW =
    window.innerWidth >= LARGE_VIEW_WIDTH ||
    window.innerWidth < MEDIUM_VIEW_WIDTH
      ? 9
      : 8;

  const pages = allPagesOverview
    ? allPagesOverview
        .filter((item) => item?.suite && item?.slug !== '')
        .slice(0, MAX_NUM_TO_SHOW)
    : [];

  const filteredPages = pages.filter(
    (page) => page?.suite?.id === listItems[filterIndex].id,
  );
  const listRef = useRef<HTMLElement | undefined>(null);
  const containerRef = useRef<HTMLElement | undefined>(null);
  const showMoreBtn = filteredPages.length > INIT_NUM_TO_SHOW && !isOpen;

  const setInitHeight = () => {
    const gap = 0.5;
    const getCardHeight = () => {
      if (window.innerWidth >= LARGE_VIEW_WIDTH) return 14.5;
      if (window.innerWidth < MEDIUM_VIEW_WIDTH) return 12.6;

      return 14.5;
    };
    const getRowsNum = () => {
      if (window.innerWidth >= LARGE_VIEW_WIDTH) return 3;
      if (window.innerWidth < MEDIUM_VIEW_WIDTH) return 9;

      return 4;
    };
    const rowsNum = getRowsNum();

    return `${getCardHeight() * rowsNum - gap * (rowsNum - 2)}rem`;
  };

  const updateHeight = () => {
    const rect =
      (listRef as RefObject<HTMLDivElement>) && listRef.current
        ? listRef.current.getBoundingClientRect()
        : { height: 0 };
    if (isOpen) {
      containerRef.current?.style.setProperty(
        '--collapse-height',
        `${rect.height}px`,
      );
    } else {
      containerRef.current?.style.setProperty(
        '--collapse-height',
        setInitHeight(),
      );
    }
  };

  const showMore = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    containerRef.current?.style.setProperty(
      '--collapse-height',
      setInitHeight(),
    );
  }, []);

  useEffect(() => {
    updateHeight();
  }, [isOpen]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;
    const resizeListener = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(updateHeight, 100);
    };
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [isOpen]);

  const handleFilter = (i: number) => {
    setFilterIndex(i);
  };

  return (
    <section>
      <div className="py-l">
        <div className="mb-m w-full">
          <em className="block not-italic h5">{title}</em>
        </div>
        <div
          aria-label="API pages overview"
          className="flex flex-wrap mb-xl space-x-3"
          role="tablist"
        >
          {listItems?.map((item, index) => (
            <Chip
              key={index}
              onClick={() => handleFilter(index)}
              selected={index === filterIndex}
              text={item.label}
            />
          ))}
        </div>
        <div
          aria-labelledby="collapse-more-button"
          className={classNames([
            'collapse-content',
            'flex',
            'flex-wrap',
            '-m-2',
            'overflow-hidden',
            'transition-all',
            'duration-1000',
            'ease-in-out',
            'delay-500',
          ])}
          ref={containerRef as RefObject<HTMLDivElement>}
        >
          <div
            aria-labelledby={listItems[filterIndex].label}
            className="grid grid-cols-2 gap-4 mx-2 w-full md:grid-cols-3"
            id={`${listItems[filterIndex].label}-tab`}
            ref={listRef as RefObject<HTMLDivElement>}
            role="tabpanel"
            tabIndex={0}
          >
            {filteredPages.map((page, index) => (
              <div
                className={classNames(
                  'h-[12.25rem] md:h-[13rem]',
                  `index-${index}`,
                  {
                    hidden: index + 1 > INIT_NUM_TO_SHOW && showMoreBtn,
                  },
                )}
                key={page?.id}
              >
                <OverviewListItem {...page} />
              </div>
            ))}
          </div>
        </div>
        <div
          className={classNames('flex justify-center', {
            'opacity-0 duration-0': !showMoreBtn,
            'duration-300 transition-all ease-in-out': showMoreBtn,
          })}
        >
          <Button
            aria-expanded={!showMoreBtn}
            className={classNames('mt-16 text-base font-normal', {
              hidden: !showMoreBtn,
            })}
            id="collapse-more-button"
            isDisabled={!showMoreBtn}
            onClick={showMore}
            type="button"
            variant="primary"
          >
            See More
          </Button>
        </div>
      </div>
    </section>
  );
};

OverviewList.pageOptions = ['allPagesOverview'];
