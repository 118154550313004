import { Button } from 'components/common/button';
import { Container } from 'components/common/container';
import { Image, ImageType } from 'components/common/image';

export type HeroSectionType = {
  title: string;
  content: string;
  callToActionLink?: string;
  callToActionLabel?: string;
  image: ImageType;
};

export const HeroSection: React.FC<HeroSectionType> = ({
  title,
  content,
  callToActionLink,
  callToActionLabel,
  image,
}) => (
  <section className="text-blue-900 dark:text-white border-b border-b-grey-200 md:pt-xxl pt-lg">
    <Container>
      <div className="grid gap-8 lg:grid-cols-2 lg:gap-0">
        <div className="my-auto lg:px-12 xl:px-24">
          <h1 className="mt-8 text-4xl font-bold leading-extra-snug">
            {title}
          </h1>
          <p className="mt-m mb-xs text-md leading-normal line-clamp-4">
            {content}
          </p>
          {callToActionLink && callToActionLabel && (
            <Button
              className="my-8 text-base font-normal leading-8"
              href={callToActionLink}
              isLink
              isOpenedInNewTab
              type="button"
              variant="primary"
            >
              {callToActionLabel}
            </Button>
          )}
        </div>
        <Image image={image} layout="responsive" priority />
      </div>
    </Container>
  </section>
);
