import React from 'react';

import { ComponentPageOptions } from 'types';

import { formatBytes, getFileName } from '../../../lib/utils';
import { DownloadLink } from '../../common/download-button';

export type DownloadLinkType = {
  url: string;
  id: string;
  size: number;
};

export type FileLinkType = {
  url: string;
  id: string;
  size?: number;
};

export type UploadFileType = {
  url: string;
  id: string;
  filename: string;
  size: number;
};

export type DownloadModuleType = {
  heading: string;
  description: string;
  fileLink: FileLinkType[];
  allUploads: UploadFileType[];
};

export const FilesList: React.FC<{
  list: DownloadLinkType[];
}> = ({ list }) => {
  return (
    <div className="ml-0 w-full text-sm">
      <div className="grid grid-cols-[auto_min-content] gap-4 w-full sm:grid-cols-[minmax(135px,min-content)_min-content_2fr] sm:auto-cols-max sm:gap-x-8">
        <strong
          className="py-xxs text-base leading-normal text-light-black dark:text-grey-100"
          data-testid="download-module-heading-package"
        >
          Package
        </strong>
        <strong
          className="py-xxs text-base leading-normal text-light-black dark:text-grey-100 sm:col-span-2"
          data-testid="download-module-heading-size"
        >
          Size
        </strong>

        {list.map((file) => (
          <React.Fragment key={`files-${file.id}`}>
            <div className="w-full text-blue-900 dark:text-grey-40 whitespace-normal sm:w-auto sm:whitespace-nowrap">
              {getFileName(file.url)}
            </div>
            <div className="whitespace-nowrap">
              {formatBytes(file.size as number)}
            </div>
            <div className="col-span-2 pb-3 -mt-2 border-b last:border-b-0 border-b-grey-100 sm:col-span-1 sm:pb-0 sm:mt-0 sm:border-b-0">
              <DownloadLink className="text-external-link" url={file.url}>
                Download
              </DownloadLink>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export const DownloadModule: React.FC<DownloadModuleType> &
  ComponentPageOptions = ({ heading, description, fileLink, allUploads }) => {
  if (!fileLink.length) return null;

  const files = fileLink
    .map((fl) => ({
      ...fl,
      size: allUploads.find((u) => u.url === fl.url)?.size,
    }))
    .filter(
      // only want valid DownloadLinkType files (i.e. have a size)
      (f: DownloadLinkType | FileLinkType): f is DownloadLinkType =>
        f.size !== undefined,
    );

  // don't display module if no valid download links
  if (files.length === 0) return null;

  return (
    <div className="px-s pt-base pb-m my-s bg-grey-20 rounded-xl border border-grey-200 dark:border-grey-500 dark:bg-opacity-15">
      <h5
        className="mb-xs text-blue-900 dark:text-grey-20 text"
        data-testid="download-module-heading"
      >
        {heading}
      </h5>
      <p
        className="mb-s text-sm leading-normal text-light-black dark:text-grey-40"
        data-testid="download-module-description"
      >
        {description}
      </p>
      {files.length === 1 ? (
        <div
          className="flex gap-7 justify-start items-center text-sm"
          data-testid="download-module-single-item"
        >
          <div className="text-blue-900 dark:text-grey-40">
            {getFileName(files[0].url)} ({formatBytes(files[0].size as number)})
          </div>
          <div className="text-external-link">
            <DownloadLink url={files[0].url}>Download</DownloadLink>
          </div>
        </div>
      ) : (
        <FilesList list={files} />
      )}
    </div>
  );
};

DownloadModule.pageOptions = ['allUploads', 'allPagesOverview'];
