import { ReactElement, ReactNode } from 'react';

import AnchorLinkIcon from 'assets/images/anchor-link-icon.svg';

type MarkdownChild = ReactElement<string>;

type HeadingProps = {
  level: number;
  children?: ReactNode[] | (string | MarkdownChild)[];
};

export const Heading: React.FC<HeadingProps> = ({ level, children }) => {
  const headingLevel = `h${level}` as keyof JSX.IntrinsicElements;
  const HeadingTag = headingLevel;
  const anchor = children && children[0]?.toString().toLowerCase();
  const formattedAnchor =
    anchor &&
    anchor
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');

  return (
    <HeadingTag
      className={`${headingLevel} group flex items-center whitespace-pre-wrap`}
      id={formattedAnchor}
    >
      <span>{children}</span>
      {level === 2 && (
        <a
          aria-hidden="true"
          className="ml-3 opacity-0 group-hover:opacity-100"
          href={`#${anchor}`}
          tabIndex={-1}
        >
          <AnchorLinkIcon height={12} width={12} />
        </a>
      )}
    </HeadingTag>
  );
};
