import Image from 'next/image';

import { SectionSeparator } from '../../common/section-separator';

type CardItemType = {
  title: string;
  content: string;
  icon: {
    url: string;
    alt: string;
  };
};

export type ProductTileSectionType = {
  heading: string;
  cardItems: CardItemType[];
};

export const ProductTileSection: React.FC<ProductTileSectionType> = ({
  heading,
  cardItems,
}) => (
  <div className="container px-5 mx-auto">
    <section className="text-blue-900 dark:text-white section">
      <h2 className="mb-xxs text-3xl font-bold leading-extra-tight text-center truncate">
        {heading}
      </h2>
      <div className="grid grid-cols-12 gap-8 mb-10">
        <SectionSeparator className="col-start-3 col-end-11 mx-l" />
      </div>
      <ul className="grid grid-cols-1 gap-8 lg:grid-cols-2">
        {cardItems.map(({ title, content, icon }: CardItemType, index) => (
          <li key={`${title}-${index}`}>
            <article className="flex h-full bg-white rounded-2xl shadow-card dark:bg-opacity-10">
              <div className="flex p-10 h-full">
                <div className="flex items-center mr-10">
                  <div className="flex justify-center items-center w-15 h-15 bg-green-200 rounded-full">
                    <Image
                      alt={icon.alt}
                      height={32}
                      layout="fixed"
                      src={icon.url}
                      width={32}
                    />
                  </div>
                </div>
                <div className="flex flex-col justify-between h-full">
                  <div className="line-clamp-6">
                    <h3 className="mb-5 text-xl leading-normal">{title}</h3>
                    <p>{content}</p>
                  </div>
                </div>
              </div>
            </article>
          </li>
        ))}
      </ul>
    </section>
  </div>
);
