import Head from 'next/head';
import { renderMetaTags, SeoMetaTagType } from 'react-datocms';

export type MetaProps = {
  pageSEO: SeoMetaTagType[];
  globalSEO: {
    favicon: SeoMetaTagType[];
  };
  metadataSchema?: string;
};

export const Meta = ({ pageSEO, globalSEO, metadataSchema }: MetaProps) => (
  <Head>
    <link href="/favicon/site.webmanifest" rel="manifest" />
    <meta content="#FFF" name="msapplication-TileColor" />
    <meta content="/favicon/browserconfig.xml" name="msapplication-config" />
    <meta content="#FFF" name="theme-color" />

    <link href="https://dev.bakkt.com" rel="canonical" />
    <meta content="Bakkt Developer Portal" name="title" />
    <meta content="Bakkt Developer Portal" name="description" />

    <script type="application/ld+json">{metadataSchema}</script>

    {/* Render Tags from CMS - optionally override the above tags */}
    {renderMetaTags(pageSEO?.concat(globalSEO.favicon) || [])}
  </Head>
);
